/* eslint-disable quotes */
/* eslint-disable object-curly-newline */
/* eslint-disable react/no-array-index-key */
import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

const EpisodeList = () => {
  const data = useStaticQuery(graphql`
    {
      contentfulEpisodesPage {
        episode {
          name
          title
          slug
          releaseDate
          duration
          description
          video {
            url
            vidoePlaceholder {
              gatsbyImageData(placeholder: BLURRED)
              url
              title
            }
          }
          featureTitle
          featuringLists {
            name
            url
          }
        }
      }
    }
  `);
  const { contentfulEpisodesPage } = data || {};
  const posts = contentfulEpisodesPage.episode;

  return (
    <section>
      <div className="flex flex-wrap">
        {posts.map(({ title, slug, description, video }, index) => (
          <Link
            key={index}
            className="mb-8 w-full md:w-1/2 lg:w-1/3 pr-[0.5rem] group duration-200 delay-75"
            to={`/episodes/${slug}`}
          >
            <div className="relative">
              <div className="transition transform duration-150 ease-in after:group-hover:border-primary after:group-hover:bottom-[-3px] after:h-0 after:w-full after:absolute after:rounder-full after:group-hover:border-b-[0.25rem] after:left-0 after:z-10">
                <GatsbyImage
                  image={video.vidoePlaceholder.gatsbyImageData}
                  alt={title}
                  className="shadow-md w-full z-0 group-hover:opacity-50 group-hover:shadow-lg"
                />
              </div>
            </div>
            <h3 className="text-white group-hover:text-primary text-h3 mt-[8px] pb-[0.1rem]">
              {title}
            </h3>
            <p className="block text-text text-sm-1 font-light w-[80%]">
              {`${description.substring(0, 50)}...`}
            </p>
          </Link>
        ))}
      </div>
    </section>
  );
};

export default EpisodeList;
